// import { Icon } from 'components/Icon/Icon';
import { Link } from "components/Link/Link";
import { PageFrame } from "components/PageFrame/PageFrame";
// import { Panel } from 'components/Panel/Panel';
// import { Section } from 'components/Section/Section';
import React from "react";
import css from "./HomeView.module.scss";
// import classes from 'classnames';
import bandPhoto from "assets/jpg/sean-stynes-band.jpg";

export const HomeView = () => {
  return (
    <>
      <PageFrame title="SEAN STYNES">
        <p className={css.links}>
          <Link
            type="link"
            to="https://www.youtube.com/watch?v=GkXlYVoPwtg"
            external
          >
            YouTube
          </Link>
          <Link
            type="link"
            to="https://open.spotify.com/album/3du7REw8UuehbUavNARyGC"
            external
          >
            Spotify
          </Link>
          <Link
            type="link"
            to="https://music.apple.com/us/artist/sean-stynes/1543522557"
            external
          >
            Apple Music
          </Link>
          <Link
            type="link"
            to="https://www.instagram.com/sean.stynes/"
            external
          >
            Instagram
          </Link>
          <Link type="link" to="https://www.tiktok.com/@sean.stynes" external>
            TikTok
          </Link>
          <Link
            type="link"
            to="https://seanstynes.bandcamp.com/releases"
            external
          >
            Bandcamp
          </Link>
          <Link
            type="link"
            to="https://distrokid.com/hyperfollow/seanstynes/heal"
            external
          >
            More
          </Link>
        </p>
        <img
          className={css.bandPhoto}
          src={bandPhoto}
          alt="Sean Stynes and Band Live at The Pyramid Scheme, Grand Rapids, MI"
        />

        <h1 className="heading">NEW ALBUM "HEAL"</h1>
        <a
          href="https://www.youtube.com/watch?v=GkXlYVoPwtg"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className={css.albumCover}
            src="https://f4.bcbits.com/img/a0383961974_10.jpg"
            alt="heal album cover"
          ></img>
        </a>
        <p>
          “After lending his talents to the Grand Rapids bands Gorgeous Oranges,
          Wala Gum and Bigfoot Buffalo, multi-instrumentalist Sean Stynes steps
          out with this mesmerizing, debut solo album, brimming with dreamy and
          psychedelic indie-rock. Weaving electronic effects and synthesizer
          passages throughout, Stynes creates an immersive, dream-pop milieu
          that gets heavier and edgier on tracks such as “Lines (Long Ago)” and
          “It’s All Around” without losing any textural beauty. Even more
          impressively, Stynes played all the instruments and recorded the
          tracks in his home studio.” – John Sinkevics
        </p>
        <p>
          Sean Stynes is a musician from Grand Rapids, MI. Combining Indie Rock
          with Psych Rock, Soul, and R&amp;B influences. You can find the debut
          album "HEAL" on all major streaming platforms.
        </p>

        <p>
          <iframe
            src="https://open.spotify.com/embed/album/3du7REw8UuehbUavNARyGC"
            width="100%"
            height="380"
            frameBorder="0"
            title="Heal"
            // @ts-ignore
            allowtransparency="true"
            allow="encrypted-media"
          ></iframe>
        </p>

        {/* <p>
					<iframe
						width="100%"
						height="700"
						src="https://www.youtube.com/embed/xluyQJmkAVM"
						title="YouTube video player"
						// @ts-ignore
						frameborder="0"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
						allowfullscreen
					></iframe>
				</p> */}
        <p>
          <h2>LIVE SHOWS</h2>
          {/* <p>No dates scheduled, check back soon.</p> */}
          <p>
            <strong>May 25</strong> - @ The Pyramid Scheme - Grand Rapids, MI
          </p>
          <p>
            <strong>June 11</strong> - @ The DAAC - Grand Rapids, MI
          </p>
          <p>
            <strong>June 24</strong> - @ Tip Top Deluxe - Grand Rapids, MI
          </p>
        </p>

        <br />
        <br />
        <p>
          <h2>SUBSCRIBE</h2>
          <p>
            <Link
              type="primary"
              to="http://eepurl.com/hE097D"
              external
              className={css.subscribeButton}
            >
              Get updates and new music
            </Link>
          </p>
        </p>
      </PageFrame>
    </>
  );
};
