import React from 'react';
import './App.scss';
import { Routes } from 'routes';
import { ErrorBoundary } from 'components/ErrorBoundary';
import { MessageProvider } from 'components/Message/MessageProvider';
import { ModalProvider } from 'components/Modal/ModalProvider';
import { BrowserRouter as Router } from 'react-router-dom';
import firebase from 'firebase/app';

firebase.initializeApp({
	apiKey: 'AIzaSyCado5q_ZDMv3YUm08hh0DNmgvd9zF6cRI',
	authDomain: 'portfolio-8b0ea.firebaseapp.com',
	projectId: 'portfolio-8b0ea',
	storageBucket: 'portfolio-8b0ea.appspot.com',
	messagingSenderId: '616792808448',
	appId: '1:616792808448:web:c0d26d7d94e701717e0ce5',
	measurementId: 'G-63JTPCKV21',
});

const App: React.FC = () => {
	return (
		<ErrorBoundary>
			<MessageProvider>
				<ModalProvider>
					<Router>
						<Routes></Routes>
					</Router>
				</ModalProvider>
			</MessageProvider>
		</ErrorBoundary>
	);
};

export const handleFirstTab = (event: KeyboardEvent) => {
	if (event.keyCode === 9) {
		// the "I am a keyboard user" key
		document.body.classList.add('user-is-tabbing');
		window.removeEventListener('keydown', handleFirstTab);
	}
};

window.addEventListener('keydown', handleFirstTab);

export default App;
