import React, { useEffect, useRef } from 'react';
import Helmet from 'react-helmet';
import { Link } from 'components/Link';
import { Icon } from 'components/Icon';
import classes from 'classnames';
import css from './PageFrame.module.scss';
import { Breadcrumbs, IBreadcrumbsProps } from 'components/Breadcrumbs';
import { Container } from 'components/Container/Container';

interface IPageFrame {
	title: string;
	backLocation?: { to: string; label: string };
	breadcrumbs?: IBreadcrumbsProps;
	ContentTopRight?: React.FC;
	classNames?: {
		pageFrameTop?: string;
		contentTopRight?: string;
		title?: string;
	};
}

export const PageFrame: React.FC<IPageFrame> = (props) => {
	const {
		title,
		children,
		backLocation,
		ContentTopRight,
		classNames,
		breadcrumbs,
	} = props;
	const titleRef = useRef<HTMLHeadingElement>();
	useEffect(() => {
		titleRef.current.focus();
	}, []);

	return (
		<>
			{/* <Header></Header> */}
			<Container>
				<div className={css.pageFrame}>
					{breadcrumbs && (
						<Breadcrumbs
							className={css.breadcrumbs}
							{...breadcrumbs}
						></Breadcrumbs>
					)}
					<div
						className={classes(
							css.pageFrameTop,
							classNames?.pageFrameTop
						)}
					>
						<div className={css.pageFrameTopInner}>
							<h2
								className={classes(
									'heading',
									'h4',
									css.pageFrameTitle,
									classNames?.title
								)}
								ref={titleRef}
								tabIndex={-1}
							>
								{title}
							</h2>
							<Helmet>
								<title>SEAN STYNES | Music</title>
							</Helmet>
							{backLocation && (
								<Link
									to={backLocation.to}
									className={css.backLocation}
								>
									<Icon
										type="CaretLeft"
										color="black"
										size="smallest"
										className={
											css.backLocationIcon
										}
									></Icon>
									{backLocation.label}
								</Link>
							)}
						</div>
						{ContentTopRight && (
							<div
								className={classes(
									css.contentTopRight,
									classNames?.contentTopRight
								)}
							>
								<ContentTopRight />
							</div>
						)}
					</div>
					<div className={css.content}>{children}</div>
				</div>
			</Container>
		</>
	);
};
