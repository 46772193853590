import React, { useState } from 'react';
import css from './Icon.module.scss';
import classes from 'classnames';
import { TIconTypes } from 'common/types/TIconTypes';
import { TIconColors } from 'common/types/TIconColors';
import { TButtonTypes } from 'common/types/TButtonTypes';
import { removePropertiesFromObjects } from 'common/utils/removePropertiesFromObjects';
import { getUniqueId } from 'common/utils/getUniqueId';

import { ReactComponent as Alert } from 'assets/svg/alert.svg';
import { ReactComponent as Bell } from 'assets/svg/bell.svg';
import { ReactComponent as Cancel } from 'assets/svg/cancel.svg';
import { ReactComponent as CancelCircle } from 'assets/svg/cancel-circle.svg';
import { ReactComponent as CapitolBuilding } from 'assets/svg/capitol-building.svg';
import { ReactComponent as Car } from 'assets/svg/car.svg';
import { ReactComponent as CaretDownFilled } from 'assets/svg/caret-down-filled.svg';
import { ReactComponent as CaretDown } from 'assets/svg/caret-down.svg';
import { ReactComponent as CaretLeft } from 'assets/svg/caret-left.svg';
import { ReactComponent as CaretRight } from 'assets/svg/caret-right.svg';
import { ReactComponent as CaretUp } from 'assets/svg/caret-up.svg';
import { ReactComponent as CaretUpFilled } from 'assets/svg/caret-up-filled.svg';
import { ReactComponent as ChartBar } from 'assets/svg/chart-bar.svg';
import { ReactComponent as CreditCardAlt } from 'assets/svg/crdit-card-alt.svg';
import { ReactComponent as CheckCircle } from 'assets/svg/check-circle.svg';
import { ReactComponent as CheckSquare } from 'assets/svg/check-square.svg';
import { ReactComponent as Download } from 'assets/svg/download.svg';
import { ReactComponent as Dropbox } from 'assets/svg/dropbox.svg';
import { ReactComponent as ExclamationCircle } from 'assets/svg/exclamation-circle.svg';
import { ReactComponent as File } from 'assets/file-icons/file.svg';
import { ReactComponent as FileSyncDropbox } from 'assets/file-icons/file-sync-dropbox.svg';
import { ReactComponent as FileSyncOneDrive } from 'assets/file-icons/file-sync-onedrive.svg';
import { ReactComponent as FolderUser } from 'assets/svg/folder-user.svg';
import { ReactComponent as Home } from 'assets/svg/home.svg';
import { ReactComponent as Inbox } from 'assets/svg/inbox.svg';
import { ReactComponent as Info } from 'assets/svg/info.svg';
import { ReactComponent as Information } from 'assets/svg/information.svg';
import { ReactComponent as Laptop } from 'assets/svg/laptop.svg';
import { ReactComponent as LayoutGrid } from 'assets/svg/layout-grid.svg';
import { ReactComponent as Location } from 'assets/svg/location.svg';
import { ReactComponent as Mail } from 'assets/svg/mail.svg';
import { ReactComponent as MoneyCircle } from 'assets/svg/money-circle.svg';
import { ReactComponent as OneDrive } from 'assets/svg/onedrive.svg';
import { ReactComponent as Other } from 'assets/svg/other.svg';
import { ReactComponent as Page } from 'assets/svg/page.svg';
import { ReactComponent as PageContent } from 'assets/svg/page-content.svg';
import { ReactComponent as Pages } from 'assets/svg/pages.svg';
import { ReactComponent as Passport } from 'assets/svg/passport.svg';
import { ReactComponent as Phone } from 'assets/svg/phone.svg';
import { ReactComponent as Search } from 'assets/svg/search.svg';
import { ReactComponent as Settings } from 'assets/svg/settings.svg';
import { ReactComponent as ShieldCheck } from 'assets/svg/shield-check.svg';
import { ReactComponent as SpinnerThin } from 'assets/svg/spinner-thin.svg';
import { ReactComponent as Stack } from 'assets/svg/stack.svg';
import { ReactComponent as StarOfLife } from 'assets/svg/star-of-life.svg';
import { ReactComponent as SwapStroke } from 'assets/svg/swap-stroke.svg';
import { ReactComponent as Time } from 'assets/svg/time.svg';
import { ReactComponent as UserCircle } from 'assets/svg/user-circle.svg';
import { ReactComponent as UserList } from 'assets/svg/user-list.svg';
import { ReactComponent as Users } from 'assets/svg/users.svg';
import { ReactComponent as Moon } from 'assets/svg/moon.svg';
import { ReactComponent as Sun } from 'assets/svg/sun.svg';

import { ToggleLayer, Arrow } from 'react-laag';
import ResizeObserver from 'resize-observer-polyfill';

export type TIconSize =
	| 'smallest'
	| 'small'
	| 'medium'
	| 'large'
	| 'largest'
	| 'giant'
	| 'custom';

export interface IIconProps {
	className?: string;
	type: TIconTypes;
	size?: TIconSize;
	color?: TIconColors;
	style?: React.CSSProperties;
	context?: TButtonTypes;
	tooltip?: string;
	preTooltipAccessibilityMessage?: string;
	postTooltipAccessibilityMessage?: string;
	tooltipAlwaysShown?: boolean;
	marginSize?: 'small' | 'medium' | 'large';
	strokeWidth?: 'none' | 'thin' | 'thick';
}

interface IRenderIcon {
	type: TIconTypes;
	iconProperties: any;
}

const RenderIcon: React.FC<IRenderIcon> = (props) => {
	const { type, iconProperties } = props;
	switch (type) {
		case 'Alert':
			return <Alert {...iconProperties} />;
		case 'Bell':
			return <Bell {...iconProperties} />;
		case 'Cancel':
			return <Cancel {...iconProperties} />;
		case 'CapitolBuilding':
			return <CapitolBuilding {...iconProperties} />;
		case 'Car':
			return <Car {...iconProperties} />;
		case 'CancelCircle':
			return <CancelCircle {...iconProperties} />;
		case 'CaretDownFilled':
			return <CaretDownFilled {...iconProperties} />;
		case 'CaretDown':
			return <CaretDown {...iconProperties} />;
		case 'CaretLeft':
			return <CaretLeft {...iconProperties} />;
		case 'CaretRight':
			return <CaretRight {...iconProperties} />;
		case 'CaretUpFilled':
			return <CaretUpFilled {...iconProperties} />;
		case 'CaretUp':
			return <CaretUp {...iconProperties} />;
		case 'ChartBar':
			return <ChartBar {...iconProperties} />;
		case 'CheckCircle':
			return <CheckCircle {...iconProperties} />;
		case 'CheckSquare':
			return <CheckSquare {...iconProperties} />;
		case 'CreditCardAlt':
			return <CreditCardAlt {...iconProperties} />;
		case 'Download':
			return <Download {...iconProperties} />;
		case 'Dropbox':
			return <Dropbox {...iconProperties} />;
		case 'ExclamationCircle':
			return <ExclamationCircle {...iconProperties} />;
		case 'File':
			return <File {...iconProperties} />;
		case 'FileSyncDropbox':
			return <FileSyncDropbox {...iconProperties} />;
		case 'FileSyncOneDrive':
			return <FileSyncOneDrive {...iconProperties} />;
		case 'FolderUser':
			return <FolderUser {...iconProperties} />;
		case 'Home':
			return <Home {...iconProperties} />;
		case 'Inbox':
			return <Inbox {...iconProperties} />;
		case 'Info':
			return <Info {...iconProperties} />;
		case 'Information':
			return <Information {...iconProperties} />;
		case 'Laptop':
			return <Laptop {...iconProperties} />;
		case 'LayoutGrid':
			return <LayoutGrid {...iconProperties} />;
		case 'Location':
			return <Location {...iconProperties} />;
		case 'Mail':
			return <Mail {...iconProperties} />;
		case 'MoneyCircle':
			return <MoneyCircle {...iconProperties} />;
		case 'OneDrive':
			return <OneDrive {...iconProperties} />;
		case 'Other':
			return <Other {...iconProperties} />;
		case 'Page':
			return <Page {...iconProperties} />;
		case 'PageContent':
			return <PageContent {...iconProperties} />;
		case 'Pages':
			return <Pages {...iconProperties} />;
		case 'Passport':
			return <Passport {...iconProperties} />;
		case 'Phone':
			return <Phone {...iconProperties} />;
		case 'Search':
			return <Search {...iconProperties} />;
		case 'Settings':
			return <Settings {...iconProperties} />;
		case 'ShieldCheck':
			return <ShieldCheck {...iconProperties} />;
		case 'SpinnerThin':
			return <SpinnerThin {...iconProperties} />;
		case 'Stack':
			return <Stack {...iconProperties} />;
		case 'StarOfLife':
			return <StarOfLife {...iconProperties} />;
		case 'SwapStroke':
			return <SwapStroke {...iconProperties} />;
		case 'Time':
			return <Time {...iconProperties} />;
		case 'UserCircle':
			return <UserCircle {...iconProperties} />;
		case 'UserList':
			return <UserList {...iconProperties} />;
		case 'Users':
			return <Users {...iconProperties} />;
		case 'Moon':
			return <Moon {...iconProperties} />;
		case 'Sun':
			return <Sun {...iconProperties} />;
	}
};

export const Icon: React.FC<IIconProps & React.SVGProps<SVGSVGElement>> = (
	props
) => {
	const {
		className,
		type,
		size = 'medium',
		color = 'gray',
		style,
		context,
		tooltip,
		tooltipAlwaysShown,
		marginSize,
		preTooltipAccessibilityMessage,
		postTooltipAccessibilityMessage,
		strokeWidth = 'thin',
	} = props;

	const iconClasses = classes(
		css.icon,
		className,
		css[size],
		css[color],
		css[type],
		css[context],
		css[strokeWidth]
	);

	const iconProperties = {
		...removePropertiesFromObjects(
			[
				'type',
				'size',
				'color',
				'context',
				'marginSize',
				'tooltipAlwaysShown',
				'preTooltipAccessibilityMessage',
				'postTooltipAccessibilityMessage',
			],
			props
		),
		className: iconClasses,
		style,
		role: 'text',
	};

	const [uniqueId] = useState(getUniqueId());
	const [mutableObject] = useState<{
		onMouseLeaveTimeout?: NodeJS.Timeout;
		onMouseEnterTimeout?: NodeJS.Timeout;
		close?: () => void;
	}>({});

	if (tooltip) {
		return (
			<ToggleLayer
				ResizeObserver={ResizeObserver}
				fixed
				isOpen={tooltipAlwaysShown}
				placement={{
					anchor: 'RIGHT_CENTER',
					autoAdjust: true,
					triggerOffset: 10,
				}}
				renderLayer={({
					isOpen,
					layerProps,
					arrowStyle,
					layerSide,
				}) =>
					isOpen && (
						<div
							{...layerProps}
							className={css.tooltip}
							role="alert"
							id={uniqueId + 'tooltip'}
							onMouseEnter={() => {
								clearTimeout(
									mutableObject.onMouseLeaveTimeout
								);
							}}
							onMouseLeave={() => {
								if (mutableObject?.close) {
									mutableObject.close();
								}
							}}
						>
							{preTooltipAccessibilityMessage && (
								<span className="screenReaderText">
									{preTooltipAccessibilityMessage}
								</span>
							)}
							{tooltip}
							{postTooltipAccessibilityMessage && (
								<span className="screenReaderText">
									{postTooltipAccessibilityMessage}
								</span>
							)}
							<Arrow
								style={arrowStyle}
								layerSide={layerSide}
								backgroundColor="#3D83FC"
								roundness={1}
							/>
						</div>
					)
				}
			>
				{({ triggerRef, open, close, isOpen }) => {
					const tooltipIconProperties: React.SVGProps<SVGSVGElement> = {
						...iconProperties,
						tabIndex: 0,
						ref: triggerRef,
						'aria-label': 'Tooltip',
						id: `${uniqueId}button`,
						'aria-expanded': isOpen,
						onMouseEnter: () => {
							mutableObject.close = close;
							mutableObject.onMouseEnterTimeout = setTimeout(
								() => {
									open();
								},
								100
							);
						},
						onMouseLeave: () => {
							clearTimeout(
								mutableObject.onMouseEnterTimeout
							);
							mutableObject.onMouseLeaveTimeout = setTimeout(
								() => {
									close();
								},
								500
							);
						},
						onClick: (event) => {
							event.stopPropagation();
							close();
							setTimeout(() => {
								open();
							}, 1);
						},
						onKeyUp: (event) => {
							if (event.keyCode === 27) {
								close();
							} else if (event.keyCode === 13) {
								close();
								setTimeout(() => {
									open();
								}, 1);
							}
						},
						onFocus: open,
						onBlur: close,
						className: classes(
							css.icon,
							className,
							{
								[css[`margin-${marginSize}`]]:
									css[marginSize],
							},
							iconProperties?.className
						),
					};

					return (
						<RenderIcon
							iconProperties={tooltipIconProperties}
							type={type}
						/>
					);
				}}
			</ToggleLayer>
		);
	}

	return <RenderIcon iconProperties={iconProperties} type={type} />;
};
