import React, { Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import * as Views from "views";
import { LoadingDelay } from "components/LoadingDelay/LoadingDelay";
import { Spinner } from "components/Spinner/Spinner";

// Wrapper component for component lazy loading
const RouteSuspense: React.FC = (props) => (
  <Suspense
    fallback={
      <LoadingDelay>
        <Spinner
          type="darker"
          size="large"
          isCenteredHorizontally
          isCenteredVertically
        ></Spinner>
      </LoadingDelay>
    }
  >
    {props.children}
  </Suspense>
);

// Definition for all the routes for the whole app
export const Routes = () => {
  return (
    <Switch>
      <Route path="/">
        <RouteSuspense>
          <Views.HomeView />
        </RouteSuspense>
      </Route>
    </Switch>
  );
};
